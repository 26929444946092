import { createSlice } from "@reduxjs/toolkit";

const propertySlice = createSlice({
  name: "property",
  initialState: {
    loading: false,
    property:[],
    propertyList: [],
    isPropertyDeleted: false,
    isPropertyUpdated: false,
    isPropertyCreated: false,
  },
  reducers: {
    singlePropertyRequest(state, action) {
      return {
        loading: true,
      };
    },
    singlePropertySuccess(state, action) {
      return {
        loading: false,
        property: action.payload,
      };
    },
    singlePropertyFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
    propertyRequest(state, action) {
      return {
        loading: true,
      };
    },
    propertySuccess(state, action) {
      return {
        loading: false,
        propertyList: action.payload,
      };
    },
    propertyFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },

    newPropertyRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    newPropertySuccess(state, action) {
      return {
        ...state,
        loading: false,
        propertyList: action.payload,
        isPropertyCreated: true,
      };
    },
    newPropertyFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        isPropertyCreated: false,
      };
    },
    clearError(state, action) {
      return {
        ...state,
        error: null,
      };
    },
    clearProduct(state, action) {
      return {
        ...state,
        propertyList: {},
      };
    },
    updatePropertyRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    updatePropertySuccess(state, action) {
      return {
        ...state,
        loading: false,
        propertyList: action.payload,
        isPropertyUpdated: true,
      };
    },
    updatePropertyFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    clearPropertyUpdated(state, action) {
      return {
        ...state,
        isPropertyUpdated: false,
      };
    },
    deletePropertyRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    deletePropertySuccess(state, action) {
      return {
        ...state,
        loading: false,
        isPropertyDeleted: true,
      };
    },
    deletePropertyFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    clearPropertyDeleted(state, action) {
      return {
        ...state,
        isPropertyDeleted: false,
      };
    },
  },
});

const { actions, reducer } = propertySlice;

export const {
  singlePropertyRequest,
  singlePropertySuccess,
  singlePropertyFail,
  propertyRequest,
  propertySuccess,
  propertyFail,
  newPropertyRequest,
  newPropertySuccess,
  newPropertyFail,
  clearError,
  clearProduct,
  updatePropertyRequest,
  updatePropertySuccess,
  updatePropertyFail,
  clearPropertyUpdated,
  deletePropertyRequest,
  deletePropertySuccess,
  deletePropertyFail,
  clearPropertyDeleted,
} = actions;

export default reducer;
