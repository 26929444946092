import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getAdminProperty } from "../actions/PropertyActions";
import Loader from "../components/Loader";
import { BiMap } from "react-icons/bi";

export default function ExploreProperty() {
  const dispatch = useDispatch();
  const { propertyList, loading } = useSelector((state) => state.propertyState);

  const [currentPage, setCurrentPage] = useState(1);
  const { keyword } = useParams("");

  const setCurrentPageNo = (pageNo) => {
    setCurrentPage(pageNo);
  };

  useEffect(() => {
    dispatch(getAdminProperty(keyword, currentPage));
  }, [dispatch, keyword, currentPage]);

  let totalPages = 5;
  const generatePages = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    return pages;
  };

  return (
    <>
      <div className="container-fluid relative px-3">
        <div className="layout-specing">
          <div className="md:flex justify-between items-center">
            <h5 className="text-lg font-semibold">Explore Properties</h5>

            <ul className="tracking-[0.5px] inline-block sm:mt-0 mt-3">
              <li className="inline-block capitalize text-[16px] font-medium duration-500 dark:text-white/70 hover:text-green-600 dark:hover:text-white">
                <Link to="/">Mybrokerr</Link>
              </li>
              <li className="inline-block text-base text-slate-950 dark:text-white/70 mx-0.5 ltr:rotate-0 rtl:rotate-180">
                <i className="mdi mdi-chevron-right"></i>
              </li>
              <li
                className="inline-block capitalize text-[16px] font-medium text-green-600 dark:text-white"
                aria-current="page"
              >
                Properties
              </li>
            </ul>
          </div>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 mt-6">
            {loading ? (
              <Loader />
            ) : (
              propertyList &&
              propertyList.products?.map((item, index) => {
                return (
                  <div
                    className="group rounded-xl bg-white dark:bg-slate-900 shadow hover:shadow-xl dark:hover:shadow-xl dark:shadow-gray-700 dark:hover:shadow-gray-700 overflow-hidden ease-in-out duration-500 w-full mx-auto"
                    key={index}
                  >
                    <div className="md:flex">
                      <div className="relative md:shrink-0">
                        <Link
                          to={`/property-detail/${item._id}`}
                          className="text-lg hover:text-green-600 font-medium ease-in-out duration-500"
                        >
                          <img
                            className="h-full w-full object-cover md:w-48"
                            src={item.imageUrls[0].image}
                            alt=""
                          />
                        </Link>
                      </div>
                      <div className="p-6 w-full">
                        <div className="md:pb-4 pb-6">
                          <Link
                            to={`/property-detail/${item._id}`}
                            className="text-lg hover:text-green-600 font-medium ease-in-out duration-500"
                          >
                            {item.name}
                          </Link>
                        </div>

                        <ul className="md:py-4 py-6 border-y border-slate-100 dark:border-gray-800 flex items-center justify-between list-none">
                          <li className="flex items-center me-4">
                            <i className="mdi mdi-arrow-expand-all text-2xl me-2 text-green-600"></i>
                            {(item.square && <span>{item.square}sqf</span>) ||
                              (item.cent && <span>{item.cent}Cent</span>)}
                          </li>

                          <li className="flex items-center me-4">
                            <BiMap className="text-2xl me-2 text-green-600" />
                            <span>{item.state}</span>
                          </li>
                        </ul>

                        <ul className="md:pt-4 pt-6 flex justify-between items-center list-none">
                          <li>
                            <span className="text-slate-400">Price</span>
                            <p className="text-lg font-medium">
                              {item.regularPrice}
                            </p>
                          </li>
                          <li>
                            <span className="text-slate-400">Id:</span>
                            <p className="text-lg font-medium">
                             {item?.propertyID}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
          {/* 
          <div className="grid md:grid-cols-12 grid-cols-1 mt-6">
            <div className="md:col-span-12 text-center">
              <nav>
                <ul className="inline-flex items-center -space-x-px">
                  <li>
                    <Link
                      to="#"
                      className="size-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-green-600 dark:hover:border-green-600 hover:bg-green-600 dark:hover:bg-green-600"
                    >
                      <i className="mdi mdi-chevron-left text-[20px]"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="size-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 hover:text-white bg-white dark:bg-slate-900 shadow-sm dark:shadow-gray-700 hover:border-green-600 dark:hover:border-green-600 hover:bg-green-600 dark:hover:bg-green-600"
                    >
                      1
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="size-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 hover:text-white bg-white dark:bg-slate-900 shadow-sm dark:shadow-gray-700 hover:border-green-600 dark:hover:border-green-600 hover:bg-green-600 dark:hover:bg-green-600"
                    >
                      2
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      aria-current="page"
                      className="z-10 size-10 inline-flex justify-center items-center mx-1 rounded-full text-white bg-green-600 shadow-sm dark:shadow-gray-700"
                    >
                      3
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="size-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 hover:text-white bg-white dark:bg-slate-900 shadow-sm dark:shadow-gray-700 hover:border-green-600 dark:hover:border-green-600 hover:bg-green-600 dark:hover:bg-green-600"
                    >
                      4
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="size-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-green-600 dark:hover:border-green-600 hover:bg-green-600 dark:hover:bg-green-600"
                    >
                      <i className="mdi mdi-chevron-right text-[20px]"></i>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div> */}

          <div className="grid md:grid-cols-12 grid-cols-1 mt-6">
            <div className="md:col-span-12 text-center">
              <nav>
                {/* Previous Button */}
                <Link
                  key={`prev-${currentPage}`}
                  onClick={() =>
                    setCurrentPageNo(currentPage > 1 ? currentPage - 1 : 1)
                  }
                  className={`size-10 inline-flex justify-center items-center mx-1 rounded-full 
                text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm 
                dark:shadow-gray-700 hover:border-green-600 dark:hover:border-green-600 
                hover:bg-green-600 dark:hover:bg-green-600 ${
                  currentPage === 1
                    ? "text-gray-400 cursor-not-allowed"
                    : "text-green-600 hover:bg-green-100"
                }`}
                  style={{ pointerEvents: currentPage === 1 ? "none" : "auto" }}
                >
                  &lt;
                </Link>

                {/* Page Numbers */}
                {generatePages().map((page) => (
                  <Link
                    key={page}
                    onClick={() => setCurrentPageNo(page)}
                    className={`px-3 py-2 border rounded ${
                      page === currentPage
                        ? "z-10 size-10 inline-flex justify-center items-center mx-1 rounded-full text-white bg-green-600 shadow-sm dark:shadow-gray-700"
                        : "size-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 hover:text-white bg-white dark:bg-slate-900 shadow-sm dark:shadow-gray-700 hover:border-green-600 dark:hover:border-green-600 hover:bg-green-600 dark:hover:bg-green-600"
                    }`}
                  >
                    {page}
                  </Link>
                ))}

                {/* Next Button */}
                <Link
                  key={`next-${currentPage}`}
                  onClick={() =>
                    setCurrentPageNo(
                      currentPage < totalPages ? currentPage + 1 : totalPages
                    )
                  }
                  className={`size-10 inline-flex justify-center items-center mx-1 rounded-full 
                text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm 
                dark:shadow-gray-700 hover:border-green-600 dark:hover:border-green-600 
                hover:bg-green-600 dark:hover:bg-green-600 ${
                  currentPage === totalPages
                    ? "text-gray-400 cursor-not-allowed"
                    : "text-green-600 hover:bg-green-100"
                }`}
                  style={{
                    pointerEvents: currentPage === totalPages ? "none" : "auto",
                  }}
                >
                  &gt;
                </Link>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
