import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

import TinySlider from "tiny-slider-react";
import "tiny-slider/dist/tiny-slider.css";

import "../../node_modules/react-18-image-lightbox/style.css";
import { LiaCompressArrowsAltSolid } from "react-icons/lia";
import { deleteProperty, getProperty } from "../actions/PropertyActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import { FiMapPin, FiPhone } from "react-icons/fi";

export default function PropertyDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, property, isPropertyDeleted } = useSelector(
    (state) => state.propertyState
  );
  let params = useParams();
  let id = params.id;

  const deleteHandler = (e, id) => {
    e.target.disabled = true;
    dispatch(deleteProperty(id));
  };

  useEffect(() => {
    dispatch(getProperty(id));

    if (isPropertyDeleted) {
      navigate("/explore-property");
    }
  }, [dispatch, id, isPropertyDeleted]);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  function daysSinceCreation(createdAt) {
    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    const timeDifference = currentDate - createdDate;
    return Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  }
  const formatDate = (createdAt) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(createdAt).toLocaleDateString(undefined, options);
  };

  const formatNumber = (num) => {
    if (num >= 10000000) {
      return (num / 10000000).toFixed(2) + " CR"; // Crores
    } else if (num >= 100000) {
      return (num / 100000).toFixed(2) + " L"; // Lakhs
    } else {
      return num.toLocaleString(); // Less than 1 Lakh
    }
  };

  const settings = {
    container: ".tiny-one-item",
    items: 1,
    controls: true,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    controlsText: [
      '<i class="mdi mdi-chevron-left "></i>',
      '<i class="mdi mdi-chevron-right"></i>',
    ],
    nav: false,
    speed: 400,
    gutter: 0,
  };

  return (
    <div className="container-fluid relative px-3">
      <div className="layout-specing">
        <div className="md:flex justify-between items-center">
          <h5 className="text-lg font-semibold">Property details</h5>

          <ul className="tracking-[0.5px] inline-block sm:mt-0 mt-3">
            <li className="inline-block capitalize text-[16px] font-medium duration-500 dark:text-white/70 hover:text-green-600 dark:hover:text-white">
              <Link to="/">Mybrokerr</Link>
            </li>
            <li className="inline-block text-base text-slate-950 dark:text-white/70 mx-0.5 ltr:rotate-0 rtl:rotate-180">
              <i className="mdi mdi-chevron-right"></i>
            </li>
            <li
              className="inline-block capitalize text-[16px] font-medium text-green-600 dark:text-white"
              aria-current="page"
            >
              Property details
            </li>
          </ul>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <section className="relative md:py-24 pt-24 pb-16">
            <div className="container relative">
              <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                <div className="lg:col-span-8 md:col-span-7">
                  <div className="grid grid-cols-1 relative">
                    <div className="tiny-one-item">
                      <TinySlider settings={settings}>
                        {property &&
                        property.imageUrls &&
                        property.imageUrls.length > 0 ? (
                          property.imageUrls.map((item, index) => {
                            return (
                              <div className="tiny-slide" key={index}>
                                <img
                                  src={item.image}
                                  className="rounded-md shadow dark:shadow-gray-700"
                                  alt={`property-image-${index}`}
                                />
                              </div>
                            );
                          })
                        ) : (
                          <p>No images available</p>
                        )}
                      </TinySlider>
                    </div>
                  </div>

                  <h4 className="text-2xl font-medium mt-6 mb-3">
                    {property?.name}
                  </h4>
                  <span className="text-slate-400 flex items-center">
                    <FiMapPin className="size-5 me-2" /> {property?.address}
                    ,&nbsp;{property?.district},&nbsp;{property?.state}
                  </span>

                  <ul className="py-6 flex items-center list-none">
                    <li className="flex items-center lg:me-6 me-4">
                      <LiaCompressArrowsAltSolid className="lg:text-3xl text-2xl me-2 text-green-600" />
                      <span className="lg:text-xl">
                        {property?.square}&nbsp;sqf
                      </span>
                    </li>

                    {/* <li className="flex items-center lg:me-6 me-4">
                    <LuBedDouble className="lg:text-3xl text-2xl me-2 text-green-600" />
                    <span className="lg:text-xl">4 Beds</span>
                  </li>

                  <li className="flex items-center">
                    <LuBath className="lg:text-3xl text-2xl me-2 text-green-600" />
                    <span className="lg:text-xl">4 Baths</span>
                  </li> */}
                  </ul>

                  <p className="text-slate-400">{property?.description}</p>
                  <br />
                  <p className="text-slate-400">{property?.descriptionTwo}</p>
                  <br />
                  <br />


                  {property?.propertyID && (
                    <h3 className="text-slate-600">
                      PROPERTY ID: {property?.propertyID}
                    </h3>
                  )}
                  {/* <p className="text-slate-400 mt-4">
                  But I must explain to you how all this mistaken idea of
                  denouncing pleasure and praising pain was born and I will give
                  you a complete account of the system, and expound the actual
                  teachings of the great explorer of the truth, the
                  master-builder of human happiness.
                </p>
                <p className="text-slate-400 mt-4">
                  Nor again is there anyone who loves or pursues or desires to
                  obtain pain of itself, because it is pain, but because
                  occasionally circumstances occur in which toil and pain can
                  procure him some great pleasure.
                </p> */}
                  {property?.gmap ? (
                    <div className="w-full leading-[0] border-0 mt-6">
                      <iframe
                        src={property?.gmap}
                        style={{ border: "0" }}
                        title="myframe"
                        className="w-full h-[500px]"
                        allowFullScreen
                      ></iframe>
                    </div>
                  ) : null}
                </div>

                <div className="lg:col-span-4 md:col-span-5">
                  <div className="sticky top-20">
                    <div className="rounded-md bg-slate-50 dark:bg-slate-800 shadow dark:shadow-gray-700">
                      <div className="p-6">
                        <h5 className="text-2xl font-medium">Price:</h5>

                        <div className="flex justify-between items-center mt-4">
                          <span className="text-xl font-medium">
                            ₹&nbsp;{formatNumber(property?.regularPrice || 0)}
                          </span>

                          {property?.propertyStatus === "Sold" ? (
                            <span className="bg-red-600/10 text-red-600 text-sm px-2.5 py-0.75 rounded h-6">
                              {property?.propertyStatus}
                            </span>
                          ) : (
                            <span className="bg-green-600/10 text-green-600 text-sm px-2.5 py-0.75 rounded h-6">
                              {property?.propertyStatus}
                            </span>
                          )}
                        </div>

                        <ul className="list-none mt-4">
                          <li className="flex justify-between items-center">
                            <span className="text-slate-400 text-sm">
                              Days on My brokerr
                            </span>
                            <span className="font-medium text-sm">
                              {property?.createdAt
                                ? daysSinceCreation(property.createdAt) === 0
                                  ? "Today"
                                  : daysSinceCreation(property.createdAt) === 1
                                  ? "Yesterday"
                                  : daysSinceCreation(property.createdAt) < 7
                                  ? `${daysSinceCreation(
                                      property.createdAt
                                    )} days ago`
                                  : formatDate(property.createdAt)
                                : "Unknown date"}
                            </span>
                          </li>
                        </ul>
                      </div>

                      <div className="flex  justify-center">
                        <div className="p-1 w-1/2 flex justify-center">
                          <Link
                            to={`/property-update/${id}`}
                            className="btn bg-green-600 hover:bg-green-700 text-white rounded-md w-full text-center"
                          >
                            Update Product
                          </Link>
                        </div>
                        <div className="p-1 w-1/2">
                          <Link
                            to=""
                            className="btn bg-red-600 hover:bg-red-700 text-white rounded-md w-full"
                            onClick={openPopup}
                          >
                            Delete Product
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="mt-12 text-center">
                      <h3 className="mb-6 text-xl leading-normal font-medium text-black dark:text-white">
                        Have Question ? Get in touch!
                      </h3>

                      <div className="mt-6">
                        <Link
                          to="/contact"
                          className="btn bg-transparent filterBtn border text-green-600 hover:text-white rounded-md"
                        >
                          <FiPhone className="align-middle me-2  " /> Contact us
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>

      <Dialog open={isPopupOpen} onClose={closePopup} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon
                      aria-hidden="true"
                      className="h-6 w-6 text-red-600"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle
                      as="h3"
                      className="text-base font-semibold text-gray-900"
                    >
                      Delete property
                    </DialogTitle>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete this property? All your
                        data will be permanently removed. This action cannot be
                        undone.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  onClick={(e) => deleteHandler(e, id)}
                  className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                >
                  Delete
                </button>
                <button
                  type="button"
                  data-autofocus
                  onClick={closePopup}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Cancel
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
