import React, { useState } from "react";
import { Link } from "react-router-dom";

import logoDark from "../assets/images/mylogoDark.png";
import logoLight from "../assets/images/mylogo.png";
import bg1 from "../assets/images/01.jpg";
import BackToHome from "../components/backToHome";
import Switcher from "../components/switcher";
import { useDispatch } from "react-redux";
import { register } from "../actions/authActions";
import { Button } from "@headlessui/react";

export default function Signup() {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [passwordError, setPasswordError] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleRegister = (e) => {
    e.preventDefault();
    // if (userData.password !== userData.confirmPassword) {
    //   setPasswordError("Passwords do not match");
    //   return;
    // }
    console.log('userData', userData)
    dispatch(register(userData));
    setPasswordError("");
  };
  return (
    <>
      <section
        className="h-screen flex items-center justify-center relative overflow-hidden bg-no-repeat bg-center bg-cover"
        style={{ backgroundImage: `url(${bg1})` }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black"></div>
        <div className="container">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
            <div className="relative overflow-hidden bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-800 rounded-md">
              <div className="p-6">
                <Link to="">
                  <img
                    src={logoDark}
                    className="mx-auto block dark:hidden"
                    alt=""
                  />
                  <img
                    src={logoLight}
                    className="mx-auto dark:block hidden"
                    alt=""
                  />
                </Link>
                <h5 className="my-6 text-xl font-semibold">Signup</h5>
                <form className="text-start" onSubmit={handleRegister}>
                  <div className="grid grid-cols-1">
                    <div className="mb-4">
                      <label className="font-semibold" htmlFor="RegisterName">
                        Your Name:
                      </label>
                      <input
                        id="RegisterName"
                        type="text"
                        className="form-input mt-3"
                        name="name"
                        value={userData.name}
                        onChange={handleInputChange}
                        required
                        placeholder="Harry"
                      />
                    </div>

                    <div className="mb-4">
                      <label className="font-semibold" htmlFor="LoginEmail">
                        Email Address:
                      </label>
                      <input
                        id="LoginEmail"
                        type="email"
                        className="form-input mt-3"
                        placeholder="name@example.com"
                        name="email"
                        value={userData.email}
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    <div className="mb-4">
                      <label className="font-semibold" htmlFor="LoginPassword">
                        Password:
                      </label>
                      <input
                        id="LoginPassword"
                        type="password"
                        className="form-input mt-3"
                        placeholder="Password:"
                        name="password"
                        value={userData.password}
                        onChange={handleInputChange}
                        required
                      />
                    </div>



                    <div className="mb-4">
                      <Button
                       type="submit"
                        className="btn bg-green-600 hover:bg-green-700 text-white rounded-md w-full"
                      >
                        Register
                      </Button>
                    </div>

                    {/* <div className="text-center">
                      <span className="text-slate-400 me-2">
                        Already have an account ?{" "}
                      </span>{" "}
                      <Button
                        type="submit"
                        className="text-black dark:text-white font-medium"
                      >
                        Sign in
                      </Button>
                    </div> */}
                  </div>
                </form>
              </div>

              {/* <div className="px-6 py-2 bg-slate-50 dark:bg-slate-800 text-center">
                <p className="mb-0 text-slate-400">
                  © {new Date().getFullYear()} Hously. Designed by{" "}
                  <Link
                    to="https://shreethemes.in/"
                    target="_blank"
                    className="text-reset"
                  >
                    Shreethemes
                  </Link>
                  .
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <BackToHome />
      <Switcher />
    </>
  );
}
