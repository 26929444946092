import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { thunk } from 'redux-thunk';


import propertyReducer from './slices/propertySlice'
import authReducer from './slices/authSlice';

const reducer = combineReducers({
    propertyState: propertyReducer,
    authState: authReducer,
  
});

const middleware = (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    thunk,
];

const store = configureStore({
    reducer: reducer,
    middleware: middleware, 
});

export default store;
