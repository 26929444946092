import './assets/css/tailwind.css'
import './App.css';
import './assets/css/materialdesignicons.min.css'
import { Route, Routes, useLocation } from 'react-router-dom';
import Index from './pages';
import Sidebar from './components/sidebar';
import TopHeader from './components/topHeader';
import { useState } from 'react';
import Footer from './components/footer';
import Switcher from './components/switcher';
import ExploreProperty from './pages/explore-property';
import PropertyDetail from './pages/property-detail';
import FavoriteProperty from './pages/favorite-property';
import AddProperty from './pages/add-property';
import Login from './pages/login';
import Signup from './pages/signup';
import Error from './pages/error';
import UpdateProperty from './pages/update-property';
import ProtectedRoute from './Routes/ProtectedRoutes';
import LockScreen from './pages/lock-screen';

function App() {
  let [toggle, setToggle] = useState(false)
  let loction = useLocation();
  return (
    <>
    {loction.pathname === '/login' || loction.pathname === '/signup' || loction.pathname === '/signup-success' || loction.pathname === '/reset-password' || loction.pathname === '/lock-screen' || loction.pathname === '/comingsoon' || loction.pathname === '/maintenance' || loction.pathname === '/error' || loction.pathname === '/thankyou'  ? 
        <Routes>
          <Route path='/login' element={<Login/>}/>
          <Route path='/signup' element={<Signup/>}/>
          <Route path='/lock-screen' element={<LockScreen/>}/>
          <Route path='/error' element={<Error/>}/>
          <Route path='*' element={<Error/>}/>
        </Routes> : 

      <div className={`${toggle ? '' : 'toggled' } page-wrapper`}>
          <Sidebar/>
          <main className="page-content bg-gray-50 dark:bg-slate-800">
              <TopHeader toggle={toggle} setToggle={setToggle} />
              <Routes>
                <Route path='/' element={<ProtectedRoute><ExploreProperty/></ProtectedRoute>}/>
                <Route path='/explore-property' element={<ProtectedRoute><ExploreProperty/></ProtectedRoute>}/>
                {/* <Route path='/property-detail' element={<ProtectedRoute><PropertyDetail/></ProtectedRoute>}/> */}
                <Route path='/property-detail/:id' element={<ProtectedRoute><PropertyDetail/></ProtectedRoute>}/>
                <Route path='/property-update/:id' element={<ProtectedRoute><UpdateProperty /></ProtectedRoute>}/>
                {/* <Route path='/favorite-property' element={<FavoriteProperty/>}/> */}
                <Route path='/add-property' element={<ProtectedRoute><AddProperty/></ProtectedRoute>}/>
              </Routes>
              <Footer/>
              <Switcher/>
          </main>
      </div>
      
    }
   
    </>
  );
}

export default App;
