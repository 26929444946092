import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateProperty } from "../actions/PropertyActions";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../components/Loader";
import { clearPropertyUpdated } from "../slices/propertySlice";

export default function UpdateProperty() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let params = useParams();
  let [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  const { property, loading, isPropertyUpdated } = useSelector(
    (state) => state.propertyState
  );

  let id = params.id;

  const [property1, setProperty] = useState({
    name: "",
    description: "",
    descriptionTwo: "",
    gmap: "",
    address: "",
    state: "",
    district: "",
    pincode: "",
    regularPrice: "",
    revenueProspect: "",
    propertyType: "",
    square: "",
    cent: "",
    propertyStatus: "",
    residential: { residentialStatus: "", residentialType: "" },
    commercial: { commercialType: "" },
    industrial: { industrialType: "" },
    agriculturalDetails: "",
    landDetails: "",
    imagesCleared: false,
  });

  const handlePropertyTypeChange = (e) => {
    const { value } = e.target;
    setProperty((prevProperty) => ({
      ...prevProperty,
      propertyType: value,
    }));
  };

  const handlePropertyChange = (e) => {
    const { name, value } = e.target;
    setProperty((prevProperty) => ({
      ...prevProperty,
      [name]: value,
    }));
  };

  const handleResidentialChange = (event) => {
    const { name, value } = event.target;

    setProperty((prevProperty) => ({
      ...prevProperty,
      residential: {
        ...prevProperty.residential,
        [name]: value,
      },
    }));
  };
  const handleCommercialChange = (event) => {
    const { name, value } = event.target;

    setProperty((prevProperty) => ({
      ...prevProperty,
      commercial: {
        ...prevProperty.commercial,
        [name]: value,
      },
    }));
  };
  const handleIndustrialChange = (event) => {
    const { name, value } = event.target;

    setProperty((prevProperty) => ({
      ...prevProperty,
      industrial: {
        ...prevProperty.industrial,
        [name]: value,
      },
    }));
  };

  const handlePropertySubmit = async (e) => {
    e.preventDefault();

    if (files && files.length === 0) {
      setProperty((prevState) => ({
        ...prevState,
        imagesCleared: false,
      }));
    }

    const formData = new FormData();

    // Handle all other properties in property1
    Object.entries(property1).forEach(([key, value]) => {
      if (typeof value === "object" && !Array.isArray(value)) {
        formData.append(key, JSON.stringify(value));
      } else if (typeof value === "boolean") {
        formData.append(key, value.toString());
      } else {
        formData.append(key, value);
      }
    });

    // Handle file uploads if present
    if (files && files.length > 0) {
      files.forEach((file) => {
        formData.append("images", file);
      });
    }

    // Dispatch update action with formData
    dispatch(updateProperty(id, formData));
  };

  function handleChange(e) {
    const selectedFiles = Array.from(e.target.files);
    setFiles((prevFiles) => prevFiles.concat(selectedFiles));
    setPreviews((prevPreviews) =>
      prevPreviews.concat(
        selectedFiles.map((file) => URL.createObjectURL(file))
      )
    );
    e.target.value = "";
  }

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setPreviews((prevPreviews) => prevPreviews.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (property) {
      setProperty((prevState) => ({
        ...prevState, // Preserve previous state
        name: property.name || "",
        description: property.description || "",
        descriptionTwo: property.descriptionTwo || "",
        gmap: property.gmap || "",
        address: property.address || "",
        state: property.state || "",
        district: property.district || "",
        pincode: property.pincode || "",
        regularPrice: property.regularPrice || "",
        revenueProspect: property.revenueProspect || "",
        propertyType: property.propertyType || "",
        square: property.square || "",
        cent: property.cent || prevState.cent,
        propertyStatus: property.propertyStatus || "",
        residential: {
          residentialStatus: property.residential?.residentialStatus || "",
          residentialType: property.residential?.residentialType || "",
        },
        commercial: {
          commercialType: property.commercial?.type || "",
        },
        industrial: {
          industrialType: property.industrial?.type || "",
        },
        agriculturalDetails: property.agriculturalDetails || "",
        landDetails: property.landDetails || prevState.landDetails,
        imagesCleared: prevState.imagesCleared, // Preserve previous value
      }));
    }

    if (files && files.length > 0) {
      setProperty((prevState) => ({
        ...prevState,
        imagesCleared: true,
      }));
    } else {
      setProperty((prevState) => ({
        ...prevState,
        imagesCleared: false,
      }));
    }
  }, [property, files]);

  useEffect(() => {
    if (isPropertyUpdated && property) {
      dispatch(clearPropertyUpdated());
      navigate(`/property-detail/${property._id}`);
      return;
    }
  }, [dispatch, id, isPropertyUpdated]);

  return (
    <div className="container-fluid relative px-3">
      <div className="layout-specing">
        <div className="md:flex justify-between items-center">
          <h5 className="text-lg font-semibold">Update Property</h5>

          <ul className="tracking-[0.5px] inline-block sm:mt-0 mt-3">
            <li className="inline-block capitalize text-[16px] font-medium duration-500 dark:text-white/70 hover:text-green-600 dark:hover:text-white">
              <a href="index.html">Hously</a>
            </li>
            <li className="inline-block text-base text-slate-950 dark:text-white/70 mx-0.5 ltr:rotate-0 rtl:rotate-180">
              <i className="mdi mdi-chevron-right"></i>
            </li>
            <li
              className="inline-block capitalize text-[16px] font-medium text-green-600 dark:text-white"
              aria-current="page"
            >
              Update Property
            </li>
          </ul>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div className="container relative">
            <div className="grid md:grid-cols-2 grid-cols-1 gap-6 mt-6">
              <div className="rounded-md shadow dark:shadow-gray-700 p-6 bg-white dark:bg-slate-900 h-fit">
                <div>
                  <p className="font-medium mb-4">
                    Upload your property image(s) here, Please click "Upload
                    Image" Button.
                  </p>

                  {files.length > 0 ? (
                    <div className="flex flex-wrap">
                      {previews.map((file, index) => (
                        <div key={index} className="relative m-2">
                          <img
                            src={file}
                            alt={`Preview ${index}`}
                            className="preview-content w-32 h-32 object-cover rounded-md"
                          />
                          <button
                            onClick={() => handleRemoveFile(index)}
                            className="absolute top-0 right-0 bg-red-500 hover:bg-red-700 text-white rounded-full p-1"
                          >
                            &times;
                          </button>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="preview-box flex justify-center rounded-md shadow dark:shadow-gray-800 overflow-hidden bg-gray-50 dark:bg-slate-800 text-slate-400 p-2 text-center small w-auto max-h-60">
                      Supports JPG, PNG and MP4 videos. Max file size : 10MB.
                    </div>
                  )}
                  <input
                    type="file"
                    id="input-file"
                    name="input-file"
                    accept="image/*"
                    onChange={handleChange}
                    hidden
                    multiple
                  />
                  <label
                    className="btn-upload btn bg-green-600 hover:bg-green-700 border-green-600 hover:border-green-700 text-white rounded-md mt-6 cursor-pointer"
                    htmlFor="input-file"
                  >
                    Upload Image
                  </label>
                </div>
              </div>

              <div className="rounded-md shadow dark:shadow-gray-700 p-6 bg-white dark:bg-slate-900 h-fit">
                <form onSubmit={handlePropertySubmit} className="">
                  <div className="grid grid-cols-12 gap-5">
                    <div className="col-span-12">
                      <label htmlFor="name" className="font-medium">
                        Title:
                      </label>
                      <input
                        name="name"
                        id="name"
                        type="text"
                        value={property1.name}
                        onChange={handlePropertyChange}
                        className="form-input mt-2"
                        placeholder="Property Title :"
                      />
                    </div>

                    <div className="col-span-12">
                      <label htmlFor="name" className="font-medium">
                        Description:
                      </label>
                      <textarea
                        name="description"
                        id="description"
                        value={property1.description}
                        onChange={handlePropertyChange}
                        className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 mt-2"
                        placeholder="Description :"
                        rows="4"
                      ></textarea>
                    </div>

                    <div className="col-span-12">
                      <label htmlFor="name" className="font-medium">
                        Facilities description :
                      </label>
                      <textarea
                        name="descriptionTwo"
                        id="descriptionTwo"
                        value={property1.descriptionTwo}
                        onChange={handlePropertyChange}
                        className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 mt-2"
                        placeholder="Description :"
                        rows="4"
                      ></textarea>
                    </div>

                    <div className="col-span-12">
                      <label htmlFor="Gmap" className="font-medium">
                        Google-map:
                      </label>
                      <input
                        name="gmap"
                        id="gmap"
                        type="text"
                        value={property1.gmap}
                        onChange={handlePropertyChange}
                        className="form-input mt-2"
                        placeholder="Google map link"
                      />
                    </div>
                    <div className="col-span-12">
                      <label htmlFor="name" className="font-medium">
                        Address:
                      </label>
                      <input
                        name="address"
                        id="address"
                        type="text"
                        value={property1.address}
                        onChange={handlePropertyChange}
                        className="form-input mt-2"
                        placeholder="Address line 1 :"
                      />
                    </div>

                    <div className="md:col-span-6 col-span-12">
                      <label htmlFor="state" className="font-medium">
                        State:
                      </label>
                      <div className="form-icon relative mt-2">
                        <i className="mdi mdi-chevron-down absolute top-2 end-4 text-green-600"></i>
                        <select
                          name="state"
                          id="state"
                          className="form-input ps-4"
                          value={property1.state}
                          onChange={handlePropertyChange}
                        >
                          <option value="">Select State</option>
                          <option value="Alabama">Alabama</option>
                          <option value="Alaska">Alaska</option>
                          <option value="Arizona">Arizona</option>
                          <option value="Arkansas">Arkansas</option>
                          <option value="California">California</option>
                          <option value="Colorado">Colorado</option>
                          <option value="Connecticut">Connecticut</option>
                          <option value="Delaware">Delaware</option>
                        </select>
                      </div>
                    </div>
                    <div className="md:col-span-6 col-span-12">
                      <label htmlFor="district" className="font-medium">
                        District:
                      </label>
                      <div className="form-icon relative mt-2">
                        <i className="mdi mdi-chevron-down absolute top-2 end-4 text-green-600"></i>
                        <select
                          name="district"
                          id="district"
                          className="form-input ps-4"
                          value={property1.district}
                          onChange={handlePropertyChange}
                        >
                          <option value="">Select District</option>
                          <option value="District1">District 1</option>
                          <option value="District2">District 2</option>
                          <option value="District3">District 3</option>
                          <option value="District4">District 4</option>
                          <option value="District5">District 5</option>
                          <option value="District6">District 6</option>
                          <option value="District7">District 7</option>
                          <option value="District8">District 8</option>
                          {/* Add more districts as needed */}
                        </select>
                      </div>
                    </div>

                    <div className="col-span-6">
                      <label htmlFor="name" className="font-medium">
                        Pincode :
                      </label>
                      <input
                        name="pincode"
                        id="pincode"
                        type="text"
                        className="form-input mt-2"
                        value={property1.pincode}
                        onChange={handlePropertyChange}
                        placeholder="Pincode :"
                      />
                    </div>
                    <div className="col-span-6">
                      <label htmlFor="name" className="font-medium">
                        Price:
                      </label>
                      <div className="form-icon relative mt-2">
                        <i className="mdi mdi-currency-inr absolute top-2 start-4 text-green-600"></i>
                        <input
                          name="regularPrice"
                          id="price"
                          type="text"
                          value={property1.regularPrice}
                          onChange={handlePropertyChange}
                          className="form-input ps-11"
                        />
                      </div>
                    </div>

                    <div className="col-span-6">
                      <label htmlFor="name" className="font-medium">
                        Area:
                      </label>
                      <div className="form-icon relative mt-2">
                        {/* <i className="mdi mdi-currency-inr absolute top-2 start-4 text-green-600"></i> */}
                        <input
                          name="square"
                          id="square"
                          type="number"
                          value={property1.square}
                          onChange={handlePropertyChange}
                          className="form-input ps-4"
                        />
                      </div>
                    </div>

                    <div className="col-span-6">
                      <label htmlFor="name" className="font-medium">
                        Area in Cent:
                      </label>
                      <div className="form-icon relative mt-2">
                        {/* <i className="mdi mdi-currency-inr absolute top-2 start-4 text-green-600"></i> */}
                        <input
                          name="cent"
                          id="cent"
                          type="number"
                          value={property1.cent}
                          onChange={handlePropertyChange}
                          className="form-input ps-4"
                        />
                      </div>
                    </div>

                    <div className="md:col-span-12 col-span-12">
                      <label htmlFor="propertyStatus" className="font-medium">
                        Status
                      </label>
                      <div className="form-icon relative mt-2">
                        <i className="mdi mdi-chevron-down absolute top-2 end-4 text-green-600"></i>
                        <select
                          name="propertyStatus"
                          id="propertyStatus"
                          className="form-input ps-4"
                          value={property1.propertyStatus}
                          onChange={handlePropertyChange}
                        >
                          <option value="">Select</option>
                          <option value="New">New</option>
                          <option value="Sold">Sold</option>
                          <option value="For Sale">For Sale</option>
                          <option value="Featured">Featured</option>
                        </select>
                      </div>
                    </div>

                    <div className="md:col-span-6 col-span-12">
                      <label htmlFor="propertyType" className="font-medium">
                        Property Type:
                      </label>
                      <div className="form-icon relative mt-2">
                        <i className="mdi mdi-chevron-down absolute top-2 end-4 text-green-600"></i>
                        <select
                          name="propertyType"
                          id="propertyType"
                          className="form-input ps-4"
                          value={property1.propertyType}
                          onChange={handlePropertyTypeChange}
                        >
                          <option value="">Select</option>
                          <option value="Residential">Residential</option>
                          <option value="Commercial">Commercial</option>
                          <option value="Industrial">Industrial</option>
                          <option value="Agricultural">Agricultural</option>
                          <option value="Land">Land</option>
                        </select>
                      </div>
                      {property1.propertyType === "Residential" && (
                        <div className="mt-2">
                          <label
                            htmlFor="residentialStatus"
                            className="font-medium"
                          >
                            Status:
                          </label>
                          <select
                            name="residentialStatus"
                            id="residentialStatus"
                            className="form-input"
                            value={property1.residential.residentialStatus}
                            onChange={handleResidentialChange}
                          >
                            <option value="">Select Status</option>
                            <option value="New">New</option>
                            <option value="Used">Used</option>
                          </select>
                          <label
                            htmlFor="residentialType"
                            className="font-medium mt-2"
                          >
                            Type:
                          </label>
                          <select
                            id="residentialType"
                            name="residentialType"
                            className="form-input"
                            value={property1.residential.residentialType}
                            onChange={handleResidentialChange}
                          >
                            <option value="">Select Type</option>
                            <option value="Flat">Flat</option>
                            <option value="Villa">Villa</option>
                            <option value="Apartment">Apartment</option>
                          </select>
                        </div>
                      )}
                      {property1.propertyType === "Commercial" && (
                        <div className="mt-2">
                          <label
                            htmlFor="commercialType"
                            className="font-medium"
                          >
                            Type:
                          </label>
                          <select
                            id="commercialType"
                            name="commercialType"
                            className="form-input ps-4"
                            value={property1.commercial.commercialType}
                            onChange={handleCommercialChange}
                          >
                            <option value="">Select Type</option>
                            <option value="Office">Office</option>
                            <option value="Shop">Shop</option>
                            <option value="Building">Building</option>
                            <option value="Others">Others</option>
                          </select>
                        </div>
                      )}
                      {property1.propertyType === "Industrial" && (
                        <div className="mt-2">
                          <label
                            htmlFor="industrialType"
                            className="font-medium"
                          >
                            Type:
                          </label>
                          <select
                            name="industrialType"
                            id="industrialType"
                            className="form-input ps-4"
                            value={property1.industrial.industrialType}
                            onChange={handleIndustrialChange}
                          >
                            <option value="">Select Type</option>
                            <option value="Land">Land</option>
                            <option value="Building">Building</option>
                            <option value="Shed">Shed</option>
                          </select>
                        </div>
                      )}

                      {property1.propertyType === "Agricultural" && (
                        <div className="col-span-12">
                          <label htmlFor="name" className="font-medium">
                            Remarks :
                          </label>
                          <input
                            name="agriculturalDetails"
                            id="agriculturalDetails"
                            type="text"
                            className="form-input mt-2"
                            value={property1.agriculturalDetails}
                            onChange={handlePropertyChange}
                          />
                        </div>
                      )}

                      {property1.propertyType === "Land" && (
                        <div className="col-span-12">
                          <label htmlFor="name" className="font-medium">
                            Remarks :
                          </label>
                          <input
                            name="landDetails"
                            id="landDetails"
                            type="text"
                            className="form-input mt-2"
                            value={property1.landDetails}
                            onChange={handlePropertyChange}
                          />
                        </div>
                      )}
                    </div>
                    <div className="md:col-span-6 col-span-12">
                      <label htmlFor="propertyType" className="font-medium">
                        Revenue Prospect:
                      </label>
                      <div className="form-icon relative mt-2">
                        <i className="mdi mdi-chevron-down absolute top-2 end-4 text-green-600"></i>
                        <select
                          name="revenueProspect"
                          id="propertyType"
                          className="form-input ps-4"
                          value={property1.revenueProspect}
                          onChange={handlePropertyChange}
                        >
                          <option value="">Select </option>
                          <option value="Fixed-amount">Fixed amount</option>
                          <option value="Percentage">Percentage</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <button
                    type="submit"
                    id="submit"
                    name="send"
                    className="btn bg-green-600 hover:bg-green-700 border-green-600 hover:border-green-700 text-white rounded-md mt-5"
                  >
                    Update Property
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
