import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import logoDark from "../assets/images/mylogoDark.png";
import logoLight from "../assets/images/mylogo.png";
import bg1 from "../assets/images/01.jpg";

import Switcher from "../components/switcher";
import BackToHome from "../components/backToHome";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/authActions";
import { Button } from "@headlessui/react";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { error, isAuthenticated } = useSelector((state) => state.authState);
  const redirect = location.search?'/'+location.search.split('=')[1]:'/';


  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };


  useEffect(() => {
    if(isAuthenticated) {
        navigate('/')
    }

    // if(error)  {
    //     toast(error, {
    //         position: toast.POSITION.BOTTOM_CENTER,
    //         type: 'error',
    //         onOpen: ()=> { dispatch(clearAuthError) }
    //     })
    //     return
    // }
},[error, isAuthenticated, dispatch, navigate])


  return (
    <>
      <section
        className="h-screen flex items-center justify-center relative overflow-hidden bg-no-repeat bg-center bg-cover"
        style={{ backgroundImage: `url(${bg1})` }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black"></div>
        <div className="container">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
            <div className="relative overflow-hidden bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-800 rounded-md">
              <div className="p-6">
                <Link to="">
                  <img
                    src={logoDark}
                    className="mx-auto block dark:hidden"
                    alt=""
                  />
                  <img
                    src={logoLight}
                    className="mx-auto dark:block hidden"
                    alt=""
                  />
                </Link>
                <h5 className="my-6 text-xl font-semibold">Login</h5>
                <form className="text-start" onSubmit={handleLogin}>
                  <div className="grid grid-cols-1">
                    <div className="mb-4">
                      <label className="font-medium" htmlFor="LoginEmail">
                        Email Address:
                      </label>
                      <input
                        id="LoginEmail"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-input mt-3"
                        placeholder="name@example.com"
                        required
                      />
                    </div>

                    <div className="mb-4">
                      <label className="font-medium" htmlFor="LoginPassword">
                        Password:
                      </label>
                      <input
                        id="LoginPassword"
                        type="password"
                        className="form-input mt-3"
                        placeholder="Password:"
                        value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                      />
                    </div>

                    {/* <div className="flex justify-between mb-4">
                      <div className="flex items-center mb-0">
                        <input
                          className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-green-600 focus:border-green-300 focus:ring focus:ring-offset-0 focus:ring-green-200 focus:ring-opacity-50 me-2"
                          type="checkbox"
                          value=""
                          id="RememberMe"
                        />
                        <label
                          className="form-checkbox-label text-slate-400"
                          htmlFor="RememberMe"
                        >
                          Remember me
                        </label>
                      </div>
                      <p className="text-slate-400 mb-0">
                        <Link to="/reset-password" className="text-slate-400">
                          Forgot password ?
                        </Link>
                      </p>
                    </div> */}

                    <div className="mb-4">
                      <Button
                        type="submit"
                        className="btn bg-green-600 hover:bg-green-700 text-white rounded-md w-full"
                      >
                        Login / Sign in
                      </Button>
                    </div>

                    <div className="text-center">
                      <span className="text-slate-400 me-2">
                        Don't have an account ?
                      </span>{" "}
                      <Link
                        to="/signup"
                        className="text-black dark:text-white font-medium"
                      >
                        Sign Up
                      </Link>
                    </div>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>
      </section>

      <BackToHome />
      <Switcher />
    </>
  );
}
