import {
  deletePropertyFail,
  deletePropertyRequest,
  deletePropertySuccess,
  newPropertyFail,
  newPropertyRequest,
  newPropertySuccess,
  propertyFail,
  propertyRequest,
  propertySuccess,
  singlePropertyFail,
  singlePropertyRequest,
  singlePropertySuccess,
  updatePropertyFail,
  updatePropertyRequest,
  updatePropertySuccess,
} from "../slices/propertySlice";
import { apiClient } from "./utils";

export const getProperty = (id) => async (dispatch) => {
  try {
    dispatch(singlePropertyRequest());
    const response = await apiClient.get(`/api/v1/get/${id}`);
    const data = response.data;
    dispatch(singlePropertySuccess(data));
  } catch (error) {
    dispatch(singlePropertyFail(error));
  }
};


export const getPropertyList =
  (
    keyword,
    currentPage,
    offer,
    propertyStatus,
    propertyType,
    residentialStatus,
    residentialType,
    limit = 9,
    startIndex = 0,
    sort = "createdAt",
    order = "desc",
    type = "Villa"
  ) =>
  async (dispatch) => {
    try {
      dispatch(propertyRequest());

      const params = [];

      if (offer) params.push(`offer=${offer}`);
      if (propertyStatus) params.push(`propertyStatus=${propertyStatus}`);
      if (propertyType) params.push(`propertyType=${propertyType}`);
      if (residentialStatus)
        params.push(`residentialStatus=${residentialStatus}`);
      if (residentialType) params.push(`residentialType=${residentialType}`);
      if (limit) params.push(`limit=${limit}`);
      if (startIndex) params.push(`startIndex=${startIndex}`);
      if (keyword) params.push(`searchTerm=${encodeURIComponent(keyword)}`);
      if (sort) params.push(`sort=${sort}`);
      if (order) params.push(`order=${order}`);
      if (type) params.push(`type=${type}`);

      const queryString = params.length > 0 ? `?${params.join("&")}` : "";

      const link = `/api/v1/get${queryString}`;

      const { data } = await apiClient.get(link);
      dispatch(propertySuccess(data));
    } catch (error) {
      dispatch(propertyFail(error.response.data.message));
    }
  };

export const createProperty = (propertyData) => async (dispatch) => {
  try {
    dispatch(newPropertyRequest());
    const { data } = await apiClient.post("/api/v1/create", propertyData);
    dispatch(newPropertySuccess(data));
  } catch (error) {
    dispatch(newPropertyFail(error.response.data.message));
  }
};

export const updateProperty = (id, propertyData) => async (dispatch) => {
  try {
    dispatch(updatePropertyRequest());
    const { data } = await apiClient.post(`/api/v1/update/${id}`, propertyData);
    dispatch(updatePropertySuccess(data));
  } catch (error) {
    dispatch(updatePropertyFail(error.response.data.message));
  }
};

export const deleteProperty = (id) => async (dispatch) => {
  try {
    dispatch(deletePropertyRequest());
    await apiClient.delete(`/api/v1/delete/${id}`);
    dispatch(deletePropertySuccess());
  } catch (error) {
    dispatch(deletePropertyFail(error.response.data.message));
  }
};

export const getAdminProperty = (keyword, currentPage) => async (dispatch) => {
  try {
    dispatch(propertyRequest());

    let link = `/api/v1/admin/get?page=${currentPage}`;

    if (keyword) {
      link += `&keyword=${keyword}`;
    }
    const { data } = await apiClient.get(link);
    dispatch(propertySuccess(data));
  } catch (error) {
    dispatch(propertyFail(error.response.data.message));
  }
};
